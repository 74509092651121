import { Box, ResponsiveContext, Image } from "grommet";
import React from "react";

interface ContentBlockProps {
  children: React.ReactNode;
  image: any;
  reverse?: boolean;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  children,
  reverse,
  image,
}) => {
  const size = React.useContext(ResponsiveContext);
  const direction = reverse ? "row-reverse" : "row";

  return (
    <Box
      align="center"
      justify="center"
      direction={size === "small" ? "column" : direction}
      gap="medium"
      margin={{ vertical: "medium" }}
      fill
      responsive
    >
      <Box fill>{children}</Box>
      <Box fill pad={{ top: "medium" }}>
        <Image src={image} />
      </Box>
    </Box>
  );
};
