import * as React from "react";
import { Anchor, Heading, Paragraph } from "grommet";
import { ContentBlock } from "../../../components/content-block";

import TribeRHImage from "../../../images/pages/solutions/Tribe.png";
import HeresMyCopyImage from "../../../images/pages/solutions/Heresmycopy.png";
import ResearchHubImage from "../../../images/pages/solutions/ResearchHUB.png";
import DigitalresearchplatformImage from "../../../images/pages/solutions/Digitalresearchplatform.png";
import InhabitantImage from "../../../images/pages/solutions/Inhabitant.png";


const SolutionsPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#7D4CDB" }}>
        Solutions
      </Heading>

      <Paragraph fill>
        Conheça as <strong style={{color:"#3600b2"}}>soluções que temos prontas</strong> para te atender.
        Processos, tecnologia e informação integrados para você.
      </Paragraph>

      <ContentBlock image={TribeRHImage}>
        <>
          <Heading level={3} style={{ color: "#7D4CDB" }}>
            Tribe RH
          </Heading>
          <Paragraph fill>
            A Plataforma <strong style={{color:"#3600b2"}}>TribeRH</strong> foi pensada e construída para
            atender e suportar <strong style={{color:"#3600b2"}}>pessoas e empresas</strong> no
            entendimento de suas necessidades básicas e direcioná-las em sua{" "}
            <strong style={{color:"#3600b2"}}>jornada de evolução na busca por excelência.</strong>
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock reverse image={HeresMyCopyImage}>
        <>
          <Heading level={3} style={{ color: "#7D4CDB" }}>
            Here's My Copy
          </Heading>
          <Paragraph fill>
            Você está pronto para experimentar uma <strong style={{color:"#3600b2"}}>nova era</strong> na{" "}
            <strong style={{color:"#3600b2"}}>criação de conteúdo</strong>? Apresentamos a você a{" "}
            <strong style={{color:"#3600b2"}}>Here's My Copy</strong>, a plataforma inovadora que está
            prestes a transformar a maneira como empresas de todos os tamanhos e
            criadores individuais abordam a{" "}
            <strong style={{color:"#3600b2"}}>geração de conteúdo.</strong> Seja um dos primeiros a
            embarcar nessa jornada extraordinária!
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock image={InhabitantImage}>
        <>
          <Heading level={3} style={{ color: "#7D4CDB" }}>
            InHabitant
          </Heading>
          <Paragraph fill>
            Sistema de <strong style={{color:"#3600b2"}}>coleta de dados</strong> de usuários com{" "}
            <strong style={{color:"#3600b2"}}>inteligência</strong> para armazenar não só dados pessoais
            como dados de comportamento também, respeitando as leis gerais de{" "}
            <strong style={{color:"#3600b2"}}>proteção de dados</strong> nacionais e internacionais. Com
            isso, te ajudamos a tirar insights dos dados, tornando-os
            <strong style={{color:"#3600b2"}}> informações valiosas</strong> para qualquer que seja a etapa
            do funil em que o usuário se encontre em relação ao seu negócio.
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock reverse image={ResearchHubImage}>
        <>
          <Heading level={3} style={{ color: "#7D4CDB" }}>
            Research HUB
          </Heading>
          <Paragraph fill>
            A ciência básica deseja obter o saber pelo seu valor intrínseco,
            enquanto que a ciência aplicada pelo saber útil.{" "}
            <strong style={{color:"#3600b2"}}>A tecnologia</strong>, por sua vez, persegue a{" "}
            <strong style={{color:"#3600b2"}}>solução de problemas</strong> práticos mediante{" "}
            <strong style={{color:"#3600b2"}}>recursos científicos,</strong> e são essas que a{" "}
            <strong style={{color:"#3600b2"}}>Research HUB</strong> trará para sua empresa!
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock image={DigitalresearchplatformImage}>
        <>
          <Heading level={3} style={{ color: "#7D4CDB" }}>
            Digital Research Platform
          </Heading>
          <Paragraph fill>
            Ferramenta de <strong style={{color:"#3600b2"}}>feedback do cliente</strong> para entender o que o seu produto
            deixou para ser desejado ou o que é muito bom e precisa de ser
            alterado. O feedback é recolhido, gerando uma camada bruta com
            <strong style={{color:"#3600b2"}}> respostas</strong>, também um dashboard resumido. A partir daí, basta refazer
            o design e continuar a monitorizar e ajustar a página até que o
            problema seja resolvido.
          </Paragraph>
        </>
      </ContentBlock>
    </>
  );
};

export default SolutionsPage;
